<template>
  <div class="w-full h-full flex flex-col">
    <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
      <Head>
        <template v-for="link in head.link" :key="link.id">
          <Link
            :id="link.id"
            :rel="link.rel"
            :href="link.href"
            :hreflang="link.hreflang"
          />
        </template>
        <template v-for="meta in head.meta" :key="meta.id">
          <Meta
            :id="meta.id"
            :property="meta.property"
            :content="meta.content"
          />
        </template>
      </Head>
      <Body
        id="body"
        class="relative bg-brand-greyblack-300 font-Abu overflow-x-hidden"
      >
        <div class="wrapper standalone-gradient h-full overflow-y-auto">
          <div
            class="spacer pt-4 px-4 md:px-6 pb-32 flex justify-start align-start"
          >
            <Logo></Logo>
          </div>
          <main ref="main" class="relative flex flex-col flex-grow">
            <slot></slot>
          </main>
        </div>
      </Body>
    </Html>
  </div>
</template>

<script setup>
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  // addSeoAttributes: true,
});
</script>

<style lang="scss" scoped></style>
